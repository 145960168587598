/* eslint-disable */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onInitialClientRender = () => {
    // jQuery(function() {
      // window.page.init();
    // });    
}

// Generated by Google Adwords. Trigger this to track
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-667403108/YhsQCI-KuJECEOSGn74C',
      'event_callback': callback
  });
  return false;
}

// Copied and pasted from https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options
function isCalendlyEvent(e) {
  return e.data.event &&
         e.data.event.indexOf('calendly') === 0;
};
 
// Copied and pasted from https://help.calendly.com/hc/en-us/articles/360020052833-Advanced-embed-options
// Listen for when a demo is booked
window.addEventListener(
  'message',
  function(e) {
    if (isCalendlyEvent(e)) {
      if (e.data.event === 'calendly.event_scheduled') {
        gtag_report_conversion();
      }
    }
  }
);

export const onClientEntry = () => {
  window.onload = () => {
    initChatlio();
  }
}

function initChatlio() {
  var _chatlio=_chatlio||[];
  window._chatlio = _chatlio;
!function(){var t=document.getElementById("chatlio-widget-embed");if(t&&window.React&&_chatlio.init)return void _chatlio.init(t,React);for(var e=function(t){return function(){_chatlio.push([t].concat(arguments))}},i=["configure","identify","track","show","hide","isShown","isOnline"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,n.setAttribute("data-embed-version","1.3");

  n.setAttribute("data-widget-id","a1d3e75e-3b2a-4ba9-6f49-f5e826660e62");
  n.setAttribute("data-start-hidden", false);
  c.parentNode.insertBefore(n,c);
 }();


 _chatlio.identify('inkcloud', {
  channel: 'IC Marketing Site'
});

 _chatlio.configure({
    "RoomName": "InkCloud",
    "titleColor": "#3cd458",
    "titleFontColor": "#fff",
    "onlineTitle": "Live Chat",
    "offlineTitle": "Contact Us",
    "agentLabel": "Support",
    "onlineMessagePlaceholder": "Type message here...",
    "offlineGreeting": "Sorry we are away, but we would love to hear from you and chat soon!",
    "offlineEmailPlaceholder": "Email",
    "offlineMessagePlaceholder": "Your message here",
    "offlineNamePlaceholder": "Name (optional but helpful)",
    "offlineSendButton": "Send",
    "offlineThankYouMessage": "Thanks for your message. We will be in touch soon!",
    "autoResponseMessage": "Question? Just type it below and we are online and ready to answer."
});

}