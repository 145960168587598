// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-test-2-tsx": () => import("./../../../src/pages/test2.tsx" /* webpackChunkName: "component---src-pages-test-2-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-blog-entry-tsx": () => import("./../../../src/templates/blog-entry.tsx" /* webpackChunkName: "component---src-templates-blog-entry-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/generic-page.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

